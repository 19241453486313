<template>
  <div class="tougao">
    <div class="tg_box">
      <div class="tg_content" v-html="arr"></div>
    </div>
  </div>
</template>
<script>
import { contribution } from "../api/index";
export default {
  name: "tougao",
  components: {},
  data() {
    return {
      arr: [],
    };
  },
  mounted() {
    this.getdata();
  },
  methods: {
    getdata() {
      contribution().then((res) => {
        this.arr = res.data.data;
        console.log(res.data.data, "cccc");
      });
    },
  },
};
</script>
<style lang="scss">
.tougao {
  width: 1182px;
  margin: 50px auto 0;
  text-align: center;
  .tg_box {
    .tg_content {
      margin: 0 auto;
    }
  }
}
</style>